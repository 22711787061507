import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Footer from '@rambler-components/footer';
// import RamblerLikes from '../RamblerLikes';
import '@rambler-components/footer/styles.css?compiled';

import s from './style.scss';

const menuLinkDesktop = [
  {
    title: 'Помощь',
    href:
      'https://help.rambler.ru/feedback/soft/',
    target: '_blank',
  },
  {
    title: 'Вакансии',
    href:
      'https://rambler-co.ru/jobs',
    target: '_blank',
  },
  {
    title: 'Условия использования',
    href:
      'https://help.rambler.ru/legal/1430',
    target: '_blank',
  },
  {
    title: 'Политика конфиденциальности',
    href:
      'https://help.rambler.ru/legal/1142',
    target: '_blank',
  },
];

const socialLinks = [
  {
    code: 'vk',
    href: 'https://vk.com/rambler',
  },
  {
    code: 'ok',
    href: 'https://ok.ru/rambler',
  },
  {
    code: 'tg',
    href: 'https://t.me/news_rambler',
  },
  {
    code: 'rss',
    href: 'https://news.rambler.ru/rss/',
  },
];

class FooterComponent extends Component {
  render() {
    return (
      <div className={s.root}>
        <Footer
          menuLinks={menuLinkDesktop}
          socialLinks={socialLinks}
          ageLimit={18}
        />
        {/* <div className={s.links}>
          <span className={s.link}>
            ©{' '}
            <a
              href="https://www.rambler.ru/?utm_source=soft&utm_content=head&utm_medium=footer&utm_campaign=self_promo"
              target="_blank"
              data-soft={this.props.stat.appPlatformType + '::footer::corp'}
            >
              Рамблер
            </a>, <span id="footer-date">{new Date().getFullYear()}</span>
          </span>
          <span className={s.link}>
            <a
              href="https://help.rambler.ru/feedback/soft/?utm_source=soft&utm_content=help&utm_medium=footer&utm_campaign=self_promo"
              target="_blank"
              data-soft={this.props.stat.appPlatformType + '::footer::help'}
            >
              Помощь
            </a>
          </span>
          <span className={s.link}>
            <a
              href="https://rambler-co.ru/jobs"
              target="_blank"
              data-soft={this.props.stat.appPlatformType + '::footer::jobs'}
            >
              Вакансии
            </a>
          </span>
          <span className={s.link}>
            <a
              href="https://help.rambler.ru/legal/soft/?utm_source=soft&utm_content=help&utm_medium=footer&utm_campaign=self_promo"
              target="_blank"
              data-soft={this.props.stat.appPlatformType + '::footer::legal'}
            >
              Пользовательское соглашение
            </a>
          </span>
        </div>
        <div className={s.social}>
          <div id="menu-share-2" className={s.share}>
            <RamblerLikes id="menu-share-2" size={30} iconSize={16} buttonBackground={'#2856f8'} />
          </div>
        </div> */}
      </div>
    );
  }
}

FooterComponent.propTypes = {
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

export default FooterComponent;
